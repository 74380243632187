.noDrag {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

body {
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  background-color: whitesmoke;
  user-select: none;
  /* background-color: ; */
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-txt {
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
}
.middle-txt {
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
}
.common-txt {
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
}

button {
  background-color: whitesmoke;
  border: 1px solid gray;
  color: black;
}

button:active {
  background-color: gray;
  border: 1px solid gray;
  color: black;
}

a {
  color: gray;
}

input {
  border-radius: 0%;
  border: 1px solid gray;
}

.shape-select {
  word-break: keep-all;
  white-space: normal;
  min-width: 40px;
}

.menu-select {
  word-break: keep-all;
  white-space: normal;
}
